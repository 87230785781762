import {defineNuxtRouteMiddleware, navigateTo, useAuthAPI, useMyFetch} from '#imports';
import {HTTP_OK}                                                       from '#mnyy/constants/response';
import {route}                                                         from '#mnyy/lib/utils';

import type {RouteLocation}      from 'vue-router';
import {useWebApp}               from 'vue-tg';
import {FORBIDDEN, LOGIN}        from '~/constants/route';
import {isExpired, isExpireSoon} from '~/lib/jwt';
import {useAuthStore}            from '~/stores/auth';

function redirectToLogin(from: RouteLocation) {
  return navigateTo(route(LOGIN, {query: {redirect_url: from.fullPath}}), {redirectCode: 302});
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.meta.layout && to.meta.layout !== 'default') return;

  const authStore = useAuthStore();
  const authApi = useAuthAPI(useMyFetch({currentRoute: from}));

  if (!authStore.isLoggedIn) {
    try {
      const webApp = useWebApp();
      if (webApp.initData) {
        const {data, code} = await authApi.telegram({
          initData: webApp.initData,
        });
        if (code == HTTP_OK) {
          authStore.setAuthResponse(data);
        }
      }
    } catch (err) {
      console.error('auth telegram error', err);
    }
  }

  if (!authStore.isLoggedIn) {
    return redirectToLogin(from);
  }

  try {
    const refreshToken = authStore.refreshToken;
    if (isExpireSoon(authStore.accessToken) && refreshToken && !isExpired(refreshToken)) {
      const {data} = await authApi.refreshToken({token: refreshToken});
      authStore.setAuthResponse(data);
    }

    if (!authStore.id) {
      const {data: userInfo} = await authApi.me();
      authStore.setUserInfo(userInfo);
    }
    if (typeof to.meta.permission === 'string') {
      const allowed = to.meta.permission.split(',').some(per => authStore.userInfo.permissionSet.has(per));
      if (!allowed) {
        return navigateTo(route(FORBIDDEN));
      }
    }
  } catch (e) {
    console.error(e);
    authStore.logout();
    return redirectToLogin(from);
  }
});
