import {configure} from 'vee-validate';

export default defineNuxtPlugin(nuxtApp => {
  configure({
    validateOnBlur       : false, // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnChange     : true, // controls if `change` events should trigger validation with `handleChange` handler
    validateOnInput      : false, // controls if `input` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
  });
});
