import { default as _403xFLqEFfO9MMeta } from "/opt/buildhome/repo/pages/403.vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as indexsTWKAz3TTQMeta } from "/opt/buildhome/repo/pages/campaigns/index.vue?macro=true";
import { default as connect_45accountq5tAmsQ9uQMeta } from "/opt/buildhome/repo/pages/connect-account.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: "403",
    path: "/403",
    component: () => import("/opt/buildhome/repo/pages/403.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLsrADI0A5CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    meta: indexsTWKAz3TTQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "connect-account",
    path: "/connect-account",
    meta: connect_45accountq5tAmsQ9uQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/connect-account.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]