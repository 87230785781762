//@ts-ignore
import {jwtDecode} from 'jwt-decode';

export function isExpired(token: string) {
  const {exp} = jwtDecode(token);

  return (Date.now() / 1000) > exp!;
}

export function isExpireSoon(token: string) {
  const {exp} = jwtDecode(token);

  return (Date.now() / 1000) >= exp! - 3600;
}
