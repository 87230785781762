import {HTTP_BAD_FORM, HTTP_BAD_REQUEST, HTTP_NOT_FOUND, HTTP_UNAUTHORIZED} from '#mnyy/constants/response';
import {getKeys}                                                            from '#mnyy/lib/utils';
import {FetchError}                                                         from 'ofetch';
import {toast}                                                              from 'vue-sonner';
import type {ApiResponse}                                                   from '~/composables/fetch';

export function handleError(err: any) {
  console.error(err);
  if (err instanceof FetchError && err.status == HTTP_UNAUTHORIZED) {
    return;
  }

  toast.error('Something went wrong.');
}

export async function withNotFoundHandler<T, R>(cb: () => Promise<ApiResponse<T, R>>, notFoundHandler: () => ApiResponse<T, R>): Promise<ApiResponse<T, R>> {
  try {
    return await cb();
  } catch (err) {
    if (err instanceof FetchError && err.status == HTTP_NOT_FOUND) {
      return notFoundHandler();
    }

    throw err;
  }
}

export async function withApiErrorHandler<T, R>(cb: () => Promise<ApiResponse<T, R>>): Promise<ApiResponse<T, R>> {
  try {
    return await cb();
  } catch (err) {
    if (err instanceof FetchError) {
      if (err.status == HTTP_BAD_REQUEST) {
        return {
          data   : err.data,
          message: err.data.message,
          code   : err.data.code,
        };
      }

      if (err.status == HTTP_BAD_FORM) {
        const fetchErrData: Record<keyof R, string[]> = err.data.errors;

        const errors = getKeys(fetchErrData).reduce((acc, key) => {
          acc[key] = fetchErrData[key][0];
          return acc;
        }, {} as Record<keyof R, string>);

        return {
          data  : err.data,
          errors: errors,
          code  : err.data.code,
        };
      }
    }

    console.error(err);

    return {
      data: undefined as T,
      code: 500,
    };
  }
}
