import {navigateTo, useRoute}                    from '#imports';
import {HTTP_UNAUTHORIZED}                       from '#mnyy/constants/response';
import {route}                                   from '#mnyy/lib/utils';
import type {$Fetch, FetchContext, FetchOptions} from 'ofetch';
import {ofetch}                                  from 'ofetch';
import {toast}                                   from 'vue-sonner';
import {LOGIN}                                   from '~/constants/route';
import {useAuthStore}                            from '~/stores/auth';

export function useMyFetch({currentRoute} = {currentRoute: useRoute()}): $Fetch {
  const authStore = useAuthStore();
  const options: FetchOptions = {
    baseURL: `${useRuntimeConfig().public.api.baseURL}/api/v1`,
    async onRequest(ctx: FetchContext) {
      const headers = (ctx.options.headers || {}) as Record<string, string>;

      if (!headers['Authorization'] && authStore.accessToken) {
        headers['Authorization'] = `Bearer ${authStore.accessToken}`;
      }

      if (!ctx.options.params) {
        ctx.options.params = {};
      }

      ctx.options.headers = headers;
    },
    async onResponseError({response, options}) {
      if (response.status == HTTP_UNAUTHORIZED) {
        authStore.logout();
        if (options.params && !options.params['noRedirect401']) {
          toast.error('Session expired, please login again.', {id: '401'});
          navigateTo(route(LOGIN, {query: {redirect_url: currentRoute.fullPath}}), {replace: true});
        }
      }
    },
  };

  return ofetch.create(options);
}

export interface ApiResponse<T, R> {
  data: T;
  errors?: Record<keyof R, string>;
  code: number;
  message?: string;
}

export interface PageableResponse<T> {
  items: T[];
  page: number;
  pageSize: number;
  total: number;
  totalPage: number;
  hasNext: boolean;
  hasPrev: boolean;
}
