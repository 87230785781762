import {withApiErrorHandler} from '#imports';

import {type ApiResponse, useMyFetch}                                                                      from '~/composables/fetch';
import type {AuthResponse, ConnectAccountReq, GoogleLoginReq, RefreshTokenReq, TelegramLoginReq, UserInfo} from '~/models/auth';

export function useAuthAPI(api = useMyFetch()) {
  return {
    google        : (data: GoogleLoginReq) => {
      return withApiErrorHandler(() => api<ApiResponse<AuthResponse, GoogleLoginReq>>('/auth/google', {
        method: 'POST',
        body  : data,
      }));
    },
    telegram      : (data: TelegramLoginReq) => {
      return withApiErrorHandler(() => api<ApiResponse<AuthResponse, TelegramLoginReq>>('/auth/telegram', {
        method: 'POST',
        body  : data,
      }));
    },
    refreshToken  : (data: RefreshTokenReq) => {
      return withApiErrorHandler(() => api<ApiResponse<AuthResponse, RefreshTokenReq>>('/auth/refresh', {
        method: 'POST',
        body  : data,
      }));
    },
    connectAccount: (data: ConnectAccountReq) => {
      return withApiErrorHandler(() => api<ApiResponse<any, RefreshTokenReq>>('/auth/connect-account', {
        method: 'POST',
        body  : data,
      }));
    },
    me            : () => api<ApiResponse<UserInfo, any>>('/auth/me'),
  };
}
