<script lang="ts" setup>
import {BackButton, ExpandedViewport} from 'vue-tg';

const router = useRouter();
const route = useRoute();
const isBackButtonVisible = ref<boolean>(false);
watch(() => route.fullPath, function () {
  isBackButtonVisible.value = history.state && !!history.state.back;
});
</script>

<template>
  <BackButton :visible="isBackButtonVisible" @click="router.back()"></BackButton>
  <ExpandedViewport/>
</template>
