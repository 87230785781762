import {defineNuxtRouteMiddleware, navigateTo} from '#imports';
import {route}                                 from '#mnyy/lib/utils';
import {HOME}                                  from '~/constants/route';
import {useAuthStore}                          from '~/stores/auth';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!to.meta.layout || to.meta.layout !== 'auth') return;

  if (useAuthStore().isLoggedIn) {
    return navigateTo(route(HOME));
  }
});
