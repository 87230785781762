import {computed}                    from '#imports';
import {useLocalStorage}             from '@vueuse/core';
import {defineStore}                 from 'pinia';
import type {AuthResponse, UserInfo} from '~/models/auth';

const defaultUserInfo: UserInfo = {
  id           : '',
  email        : '',
  avatar       : '',
  permissions  : [],
  permissionSet: new Set(),
};

const defaultAuthTokens: AuthTokens = {
  accessToken : '',
  refreshToken: '',
};

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

export const useAuthStore = defineStore('auth', () => {
  const token = useLocalStorage<AuthTokens>('auth/token', defaultAuthTokens);
  const userInfo = ref<UserInfo>(defaultUserInfo);

  function setToken(newTokens: AuthTokens): void {
    token.value = newTokens;
  }

  function setAuthResponse(authResponse: AuthResponse): void {
    setToken({
      accessToken : authResponse.token,
      refreshToken: authResponse.refreshToken,
    });
  }

  function setUserInfo(newUserInfo: UserInfo): void {
    if (newUserInfo) {
      newUserInfo.permissionSet = new Set(newUserInfo.permissions);
    }
    userInfo.value = newUserInfo;
  }

  function logout() {
    setToken(defaultAuthTokens);
    setUserInfo(defaultUserInfo);
  }

  return {
    id          : computed(() => userInfo.value.id),
    userInfo    : computed(() => userInfo.value),
    isLoggedIn  : computed(() => !!token.value.accessToken),
    accessToken : computed(() => token.value.accessToken),
    refreshToken: computed(() => token.value.refreshToken),
    setUserInfo,
    setAuthResponse,
    logout,
  };
});
